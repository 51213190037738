import React, { useState, useEffect, useRef } from "react"
import { navigate } from 'gatsby-link'

const Teleconsultation = () => {
    const checkDevice = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        // iOS detection
        
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        // alert(/iPad|iPhone|iPod/.test(userAgent));
            navigate(`https://apps.apple.com/us/app/hinduja-hospital-teleconsult/id1660586199`)
        } else{
            navigate(`https://play.google.com/store/apps/details?id=com.pdhinduja.teleconsultapp`)
        }
    }
    useEffect( () => {
        checkDevice()
    },[])
    return (
        <>
            <head>
                <meta name="SKYPE_TOOLBAR" content="SKYPE_TOOLBAR_PARSER_COMPATIBLE" />
                <link rel="profile" href="https://gmpg.org/xfn/11" />
            </head>
            <body>
                
            </body>
        </>
    )
}

export default Teleconsultation